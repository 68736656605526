import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import SiteLink from "../../components/SiteLink";

const Articles = () => {
	return (
		<div>
			<SEO
				title="Articles/Blog Posts"
				keywords={[
					`Blog`,
					`Articles`,
					`Information`,
					`Research`,
					`Endoscopy`,
					`Gastroenterologist`,
					`Dr Frank Brennan`,
					`Dr Spiro Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Claire Gordon`,
					`Dr Duy Pham`,
				]}
				description="Gastroenterology and Endoscopy related articles and blog posts"
			/>
			<H1 text="Patient Articles" />
			<ul>
				<SiteLink to="/articles/abdominal-bloating">
					<li>Abdominal Bloating</li>
				</SiteLink>
				<SiteLink to="/articles/bowel-cancer-screening">
					<li>Bowel Cancer Screening</li>
				</SiteLink>
				<SiteLink to="/articles/bowel-polyps">
					<li>Bowel Polyps</li>
				</SiteLink>
				<SiteLink to="/articles/capsule-endoscopy">
					<li>Capsule Endoscopy</li>
				</SiteLink>
				<SiteLink to="/articles/coeliac-disease">
					<li>Coeliac Disease</li>
				</SiteLink>
				<SiteLink to="/articles/constipation">
					<li>Constipation</li>
				</SiteLink>
				<SiteLink to="/articles/diverticulosis-and-diverticulitis">
					<li>Diverticulosis and Diverticulitis</li>
				</SiteLink>
				<SiteLink to="/articles/endoscopic-retrograde-cholangiopancreatography-ERCP">
					<li>Endoscopic Retrograde Cholangiopancreatography (ERCP)</li>
				</SiteLink>
				<SiteLink to="/articles/fatty-liver">
					<li>Fatty Liver</li>
				</SiteLink>
				<SiteLink to="/articles/gallstones">
					<li>Gallstones</li>
				</SiteLink>
				<SiteLink to="/articles/gastroenteritis-in-children">
					<li>Gastroenteritis in Children</li>
				</SiteLink>
				<SiteLink to="/articles/haemochromatosis">
					<li>Haemochromatosis</li>
				</SiteLink>
				<SiteLink to="/articles/heartburn-and-reflux">
					<li>Heartburn (Reflux)</li>
				</SiteLink>
				<SiteLink to="/articles/helicobacter-pylori">
					<li>Helicobacter Pylori</li>
				</SiteLink>
				<SiteLink to="/articles/hepatitis-a">
					<li>Hepatitis A</li>
				</SiteLink>
				<SiteLink to="/articles/hepatitis-b">
					<li>Hepatitis B</li>
				</SiteLink>
				<SiteLink to="/articles/hepatitis-c">
					<li>Hepatitis C</li>
				</SiteLink>
				<SiteLink to="/articles/inflammatory-bowel-disease-IBD-crohns-and-colitis">
					<li>Inflammatory Bowel Disease (Crohn&apos;s & Colitis)</li>
				</SiteLink>
				<SiteLink to="/articles/iron-deficiency">
					<li>Iron deficiency</li>
				</SiteLink>
				<SiteLink to="/articles/irritable-bowel-syndrome-IBS">
					<li>Irritable Bowel Syndrome (IBS)</li>
				</SiteLink>
				<SiteLink to="/articles/lactase-deficiency-lactose-intolerance">
					<li>Lactase Deficiency (lactose intolerance)</li>
				</SiteLink>
				<SiteLink to="/articles/non-coeliac-gluten-sensitivity-real-or-imagined">
					<li>Non Coeliac Gluten Sensitivity: Real or Imagined?</li>
				</SiteLink>
				<SiteLink to="/articles/ppis-and-dementia">
					<li>PPI&apos;s and Dementia</li>
				</SiteLink>
				<SiteLink to="/articles/pruritis-ani">
					<li>Pruritis Ani</li>
				</SiteLink>
			</ul>
		</div >
	);
};

export default Articles;
